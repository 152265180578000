import { SelectOption } from 'src/components'
import moment from 'moment/moment'
import { Moment } from 'moment'

enum EDateRangeOptions {
    TODAY = 'Сегодня',
    CURRENT_WEEK = 'Текущая неделя',
    CURRENT_MONTH = 'Текущий месяц',
    CURRENT_YEAR = 'Текущий год',
    YESTERDAY = 'Вчера',
    LAST_WEEK = 'Прошлая неделя',
    LAST_MONTH = 'Прошлый месяц',
    LAST_YEAR = 'Прошлый год',
    ALL_TIME = 'Всё время',
}

function getThisMonday() {
    if (moment().day() === 0) {
        // today is Sunday
        return moment().subtract(6, 'days')
    }
    return moment().set('weekday', 1)
}

function getThisMonthFirstDay() {
    return moment().set('date', 1)
}

function getThisYearsFirstDay() {
    return moment().set('month', 0).set('date', 1)
}

function getStartOfDay(date: Moment) {
    return date.utc().set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0)
}

function getEndOfDay(date: Moment) {
    // return getStartOfDay(date).add(1, 'days').subtract(1, 'millisecond');
    return date.utc().set('hours', 23).set('minutes', 59).set('seconds', 59).set('milliseconds', 999)
}

const DateRangeDates = {
    TODAY: {
        start: getStartOfDay(moment()),
        end: getEndOfDay(moment()),
    },
    CURRENT_WEEK: {
        start: getStartOfDay(getThisMonday()),
        end: getEndOfDay(moment()),
    },
    CURRENT_MONTH: {
        start: getStartOfDay(getThisMonthFirstDay()),
        end: moment().subtract(0, 'days'),
    },
    CURRENT_YEAR: {
        start: getStartOfDay(getThisYearsFirstDay()),
        end: getEndOfDay(moment()),
    },
    YESTERDAY: {
        start: getStartOfDay(moment().subtract(1, 'day')),
        end: getEndOfDay(moment().subtract(1, 'day')),
    },
    LAST_WEEK: {
        start: getStartOfDay(getThisMonday().subtract(1, 'week')),
        end: getEndOfDay(getThisMonday().subtract(1, 'week').add(6, 'days')),
    },
    LAST_MONTH: {
        start: getStartOfDay(getThisMonthFirstDay().subtract(1, 'month')),
        end: getEndOfDay(getThisMonthFirstDay().subtract(1, 'month').endOf('month')),
    },
    LAST_YEAR: {
        start: getStartOfDay(getThisYearsFirstDay().subtract(1, 'year')),
        end: getEndOfDay(getThisYearsFirstDay().subtract(1, 'year').endOf('year')),
    },
    ALL_TIME: 'ALL_TIME',
}

const datePeriodOptions: SelectOption[] = [
    { label: 'Сегодня', value: EDateRangeOptions.TODAY, date: DateRangeDates.TODAY },
    { label: 'Текущая неделя', value: EDateRangeOptions.CURRENT_WEEK, date: DateRangeDates.CURRENT_WEEK },
    { label: 'Текущий месяц', value: EDateRangeOptions.CURRENT_MONTH, date: DateRangeDates.CURRENT_MONTH },
    { label: 'Текущий год', value: EDateRangeOptions.CURRENT_YEAR, date: DateRangeDates.CURRENT_YEAR },
    { label: 'Вчера', value: EDateRangeOptions.YESTERDAY, date: DateRangeDates.YESTERDAY },
    { label: 'Прошлая неделя', value: EDateRangeOptions.LAST_WEEK, date: DateRangeDates.LAST_WEEK },
    { label: 'Прошлый месяц', value: EDateRangeOptions.LAST_MONTH, date: DateRangeDates.LAST_MONTH },
    { label: 'Прошлый год', value: EDateRangeOptions.LAST_YEAR, date: DateRangeDates.LAST_YEAR },
    { label: 'Всё время', value: EDateRangeOptions.ALL_TIME },
]

export { datePeriodOptions, EDateRangeOptions }
