import { RegularText, MediumText, GridTable, GridTableItem, Spinner, GridTableHead } from 'src/components'
import { useAtomValue, useSetAtom } from 'jotai'
import {
    couriersAtom,
    fetchAllCouriersAtom,
    selectCourierAtom,
    selectedCourierAtom,
    courierSearchQueryAtom,
} from 'src/atoms/allCouriers/allCouriersAtom'
import { setCourierEditAtom } from 'src/atoms/allCouriers/courierEditModalAtom'
import React, { useEffect, useMemo } from 'react'
import { DateUtils } from 'src/utils'
import { courierDateRangeAtom } from 'src/atoms/allCouriers/allCouriersQueryParamsAtom'

import styles from './styles.module.scss'

const theads = [
    'Таб. номер',
    'Статус смены',
    'Фамилия',
    'Телефон',
    'Статус заказа',
    'Общая сумма доставок',
    'Общее количество доставок',
    'Среднее количество доставок в день',
    'Пройденное расстояние',
    'Среднее пройденное расстояние',
    'Расстояние в пустую',
    'Среднее время доставки',
    'Время впустую на смене',
    'Дата увольнения',
    'Комментарий',
]

export const isColorCourer = (isActiveOrder: number | undefined, activeCourer: boolean, isActiveToday?: boolean) => {
    if (activeCourer) {
        return 'rgb(147 205 245)'
    }

    if (isActiveToday) {
        if (typeof isActiveOrder === 'number') {
            if (isActiveOrder > 0) {
                return '#ffbcbc'
            }
        }
        return '#bcffbc'
    }
    return ''
}

export const CouriersTable = (): JSX.Element => {
    const editCourier = useSetAtom(setCourierEditAtom)
    const selectCourier = useSetAtom(selectCourierAtom)
    const selectedCourier = useAtomValue(selectedCourierAtom)
    const { data, isLoading } = useAtomValue(couriersAtom)
    const { dateFrom, dateTo } = useAtomValue(courierDateRangeAtom)
    const searchQuery = useAtomValue(courierSearchQueryAtom)

    const fetchCouriers = useSetAtom(fetchAllCouriersAtom)

    useEffect(() => {
        fetchCouriers()
    }, [fetchCouriers, dateFrom, dateTo])

    const filteredCouriers = useMemo(() => {
        if (!searchQuery) return data
        const lowerSearchQuery = searchQuery.toLowerCase()
        return data?.filter(
            courier =>
                courier.item.name.toLowerCase().includes(lowerSearchQuery) ||
                (courier.item.phoneNumber && courier.item.phoneNumber.includes(searchQuery)) ||
                (courier.item.tabNumber && courier.item.tabNumber.toString().toLowerCase().includes(lowerSearchQuery)) ||
                (courier.item.firstName && courier.item.firstName.toLowerCase().includes(lowerSearchQuery)) ||
                (courier.item.surname && courier.item.surname.toLowerCase().includes(lowerSearchQuery)),
        )
    }, [data, searchQuery])

    if (isLoading) {
        return <Spinner />
    }

    return (
        <GridTable
            className={styles.table}
            theads={theads.map(head => (
                <GridTableHead className='text-nowrap whitespace-nowrap p-2 bg-aqua-400' key={head} title={head} />
            ))}>
            {filteredCouriers?.map(courier => {
                const isActive = selectedCourier?.item.courier_id === courier.item.courier_id

                const { tabNumber, phoneNumber, comment, fire_date, courier_id, surname } = courier.item
                const {
                    totalOrderCount,
                    totalOrderPrice,
                    isWorkingToday,
                    averageOrderTime,
                    pointlessWalking,
                    averageOrdersPerDay,
                    averageWalkage,
                    activeOrderCount,
                    pointlessWorkTime,
                    walkingDistance,
                } = courier

                return (
                    <tr key={courier_id}>
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{tabNumber}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{isWorkingToday ? 'Работает' : 'Не работает'}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{surname}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{phoneNumber}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{activeOrderCount}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{totalOrderPrice}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{totalOrderCount}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{averageOrdersPerDay}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{walkingDistance}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{averageWalkage}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{pointlessWalking}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{averageOrderTime}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{pointlessWorkTime}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{fire_date ? DateUtils.formatDate(fire_date) : ''}</RegularText>}
                        />
                        <GridTableItem
                            style={{ backgroundColor: isColorCourer(activeOrderCount, isActive, isWorkingToday) }}
                            onClick={() => selectCourier(courier)}
                            onDoubleClick={() => editCourier(courier)}
                            child={<RegularText>{comment}</RegularText>}
                        />
                    </tr>
                )
            })}
        </GridTable>
    )
}
