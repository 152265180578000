import { z } from 'zod'

import { OrderSchema } from './OrderSchema'

const RestGroupSchema = z.object({
    createdAt: z.string(),
    restgroup_id: z.string(),
    title: z.string(),
    updatedAt: z.string(),
})

const RestAddressSchema = z.object({
    city: z.string(),
    house: z.string(),
    corpus: z.string(),
    street: z.string(),
})

const RestMapFiltersSchema = z.object({
    city: z.string().optional(),
    showOnlyNoCourier: z.boolean().optional(),
    q: z.string().optional(),
    groupId: z.string().optional(),
    openedType: z.string().optional(), // onlyOpened | onlyClosed | onlyOpenedAndClosed | onlyBlocked
})

const RestSchema = z.object({
    rest_id: z.string(),
    restName: z.string(),
    servType: z.string(),
    cashPaymentKind: z.string(),
    cashPaymentId: z.string(),
    onlinePaymentKind: z.string(),
    onlinePaymentId: z.string(),
    restImageUrl: z.string(),
    serviceFee: z.string(),
    address: RestAddressSchema,
    orderMethods: z.string().array().optional(),
    cityKey: z.string(),
    isOpen: z.boolean().nullish(),
    isOurCouriers: z.boolean().nullish(),
    latitude: z.number(),
    longitude: z.number(),
    maxPrepareTime: z.string(),
    minOrderPrice: z.number(),
    paymentMethods: z.string().array().optional(),
    phoneNumber: z.string(),
    checkbox: z.boolean(),
    reviewCount: z.string().nullish(),
    kitchenType: z.string().nullish(),
    code: z.string().nullish(),
    manager: z.string(),
    whatsApp: z.string(),
    telegram: z.string(),
    email: z.string().nullish(),
    website: z.string(),
    comment: z.string(),
    starCount: z.string().nullish(),
    restPercent: z.string().optional().or(z.number().optional()),
    is_blocked: z.boolean().nullish(),
    schedule: z.array(
        z.object({
            to: z.string(),
            day: z.string(),
            from: z.string(),
            breakTo: z.string(),
            breakFrom: z.string(),
        }),
    ),
    restGroup: z
        .object({
            restgroup_id: z.string(),
            title: z.string(),
            createdAt: z.string(),
            updatedAt: z.string(),
        })
        .optional(),
    group_id: z.string(),
    order_region: z.array(z.array(z.number(), z.number())),
    apiLogin: z.string(),
    apiKey: z.string(),
    organizationId: z.string(),
    terminalgroupId: z.string(),
    shipPrice: z.number(),
    restType: z.string().nullish(),
    login: z.string(),
    password: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    tab_number: z.string().optional(),
})

const RestOrderSchema = z.object({
    order_id: z.string().optional(),
    title: z.string().optional(),
    restId: z.string().optional(),
    phoneNumber: z.string().optional(),
    imageUrl: z.string().optional(),
    smallImageUrl: z.string().optional(),
    dishIdArray: z
        .object({
            amount: z.number().optional(),
            price: z.number().optional(),
            productId: z.string().optional(),
            type: z.string().optional(),
        })
        .array(),
    paid: z.boolean(),
    courier_id: z.string().optional(),
    status: z.string().optional(),
    clientId: z.string().optional(),
    courierStatus: z.string().optional(),
    price: z.number().optional(),
    paymentType: z.string().optional(),
    deliveryPrice: z.number().optional(),
    foodCost: z.number().optional(),
    shipCost: z.number().optional(),
    serviceFee: z.number().optional(),
    deliveryTime: z.number().optional(),
    isOurCouriers: z.boolean(),
    comment: z.string().optional(),
    deliveryAddress: z.string().optional(),
    orderNumber: z.number().optional(),
    cancel_reason: z.string().optional(),
    shipType: z.string().optional(),
    servtype: z.string().optional(),
    pickup: z.boolean(),
    tookDate: z.string().optional(),
    deliveryDate: z.string().optional(),
    discountBonus: z.number().optional(),
    realPrice: z.number().optional(),
    was_paid_by_lokaly: z.string().optional(),
    courier_was_paid_by_lokaly: z.string().optional(),
    clientLong: z.number().optional(),
    clientLat: z.number().optional(),
    restName: z.string().optional(),
    clientName: z.string().optional(),
    clientPhoneNumber: z.string().optional(),
    restPhoneNumber: z.string().optional(),
    restAddress: z.string().optional(),
    courierPhoneNumber: z.string().optional(),
    courierTabNumber: z.number().optional(),
    courierName: z.string().optional(),
    spentTimeOnCooking: z.number().optional(),
    spentTimeOnDelivery: z.number().optional(),
    iikoOrderId: z.string().optional(),
    orderCooked: z.string().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    orderTookDate: z.string().optional(),
    orderDeliveryDate: z.string().optional(),
})

const financesSchema = z.object({
    createdAt: z.string(),
    date: z.string(),
    order_id: z.string(),
    serviceFee: z.string().optional(),
    restTabNumber: z.number().nullish(),
    restTitle: z.string(),
    rest_id: z.string(),
    restfinance_id: z.string(),
    title: z.string().nullish(),
    totalPayment: z.number(),
    updatedAt: z.string(),
    was_paid: z.boolean(),
    localiPercent: z.number(),
    totalPaymentSnapshot: z.number(),
    totalSum: z.number(),
    deliveryPrice: z.number(),
    totalSeizure: z.number(),
})

const RestAccountingSchema = z.object({
    finances: financesSchema.array(),
    total: z.object({
        totalSumCollected: z.number(),
        totalPaymentCollected: z.number(),
        deliveryPriceCollected: z.number(),
        totalOrderCount: z.number(),
        totalServiceFee: z.number(),
    }),
})

const RestAccountingOrdersSchema = z.object({
    ordersArray: z
        .object({
            restName: z.string(),
            orderNumber: z.number(),
            orderDate: z.number(),
            orderPayment: z.number(),
            paymentType: z.string(),
            foodCost: z.number(),
            deliveryPrice: z.number(),
        })
        .array(),
    serviceFee: z.number(),
    cashPayment: z.number(),
    onlinePayment: z.number(),
    fullPayment: z.number(),
    fullOrderCount: z.number(),
    fullServiceFee: z.number(),
    fullDeliveryPrice: z.number(),
    fullFoodCost: z.number(),
})

const RestStatsResponseSchema = z.object({
    averageCookingTime: z.number(),
    averageOrderCount: z.number(),
    averageOrderPrice: z.number(),
    favoriteCount: z.number().nullish(),
    maxCookingTime: z.number(),
    minCookingTime: z.number(),
    done: z.number(),
    going: z.number(),
    popularityPercentage: z.number(),
    orders: z.array(OrderSchema),
})

type Rest = z.infer<typeof RestSchema>

type RestGroup = z.infer<typeof RestGroupSchema>

type RestMapFilters = z.infer<typeof RestMapFiltersSchema>

type RestOrder = z.infer<typeof RestOrderSchema>

type RestStatsResponse = z.infer<typeof RestStatsResponseSchema>

type RestAccounting = z.infer<typeof RestAccountingSchema>

type FinancesAccounting = z.infer<typeof financesSchema>

type RestAccountingOrders = z.infer<typeof RestAccountingOrdersSchema>

export type { RestGroup, Rest, RestOrder, RestMapFilters, RestAccounting, RestAccountingOrders, RestStatsResponse, FinancesAccounting }

export {
    RestGroupSchema,
    RestSchema,
    RestStatsResponseSchema,
    RestOrderSchema,
    RestAccountingSchema,
    RestAccountingOrdersSchema,
    RestMapFiltersSchema,
}
