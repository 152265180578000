import React, { useCallback, useState, useEffect, useMemo } from 'react'
import moment from 'moment/moment'
import { useSetAtom } from 'jotai'
import {
    AppSelect,
    Button,
    DateInput,
    EButtonVariant,
    GridTable,
    GridTableHead,
    GridTableItem,
    HeaderLayout,
    MediumText,
    RoundedWrapper,
    SelectOption,
    Spinner,
    SuspenseWithSpinner,
} from 'src/components'
import classNames from 'classnames'
import Select from 'react-select'

import { FinancesAccounting, LokaliApi, RestAccounting } from '../../services'
import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import { currentRestAccountingAtom, setRestAccountingModalAtom } from '../../atoms/restaraunts/restaurantAccountingAtom'
import { DateFilterAuto } from '../../components/DateFilterAuto'
import { Excel } from '../../services/Excel'
import { DateFilterSelect } from '../../components/DateFilterSelect'

import styles from './styles.module.scss'
import { useAtomValue } from 'jotai/index'
import { getRestGroupAtom } from '../../atoms/restaraunts/createRestGroupAtom'
import { z } from 'zod'
import { getRestAtom } from '../../atoms/restaraunts/restAtom'

const THEADS = [
    'Таб. номер',
    'Название ресторана',
    'Общая сумма',
    'Сумма доставки',
    'Процент локали',
    'Сервисный сбор',
    'Расчет с рестораном',
    'Дата выплаты',
    'Статус выплаты',
]

export const AccountingRestPage = (): JSX.Element => {
    const setRestAccountingModal = useSetAtom(setRestAccountingModalAtom)
    const setCurrentRestAccounting = useSetAtom(currentRestAccountingAtom)

    const [statusPaiment, setStatusPaiment] = useState<SelectOption>()

    const { data } = useAtomValue(getRestAtom)

    const [total, setTotal] = useState<{
        totalSumCollected: number
        totalPaymentCollected: number
        deliveryPriceCollected: number
        totalServiceFee: number
        totalOrderCount: number
    }>({
        totalPaymentCollected: 0,
        totalSumCollected: 0,
        deliveryPriceCollected: 0,
        totalServiceFee: 0,
        totalOrderCount: 0,
    })

    const [restAccounting, setRestAccounting] = useState<FinancesAccounting[]>([])
    const [dateFrom, setDateFrom] = useState<string>(moment().startOf('week').format('YYYY-MM-DD'))
    const [dateTo, setDateTo] = useState<string>(moment().endOf('week').format('YYYY-MM-DD'))
    const [selectedRestaurant, setSelectedRestaurant] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [idRestaurant, setIdRestaurant] = useState<SelectOption>()

    const getRestAccounting = useCallback(async () => {
        setIsLoading(true)
        try {
            // console.log(moment(dateTo).format(''));
            const params = {
                dateFrom,
                dateTo,
                rest_id: idRestaurant?.value,
                paid: statusPaiment?.value,
            }

            const data = await LokaliApi.getRestAccounting(params)
            setRestAccounting(data.finances)
            setTotal(data.total)
        } catch (e) {
            console.warn('Error fetching restaurant accounting:', e)
        } finally {
            setIsLoading(false)
        }
    }, [dateFrom, dateTo, idRestaurant?.value, statusPaiment?.value])

    useEffect(() => {
        const timer = setTimeout(() => {
            getRestAccounting().then()
        }, 300)

        return () => clearTimeout(timer)
    }, [dateFrom, dateTo, idRestaurant?.value, statusPaiment?.value])

    const onRefreshClick = () => getRestAccounting()

    const handleClick = useCallback(
        (accounting: FinancesAccounting) => () => {
            setSelectedRestaurant(accounting.restfinance_id)
            setCurrentRestAccounting(accounting)
        },
        [setCurrentRestAccounting],
    )

    const handleDoubleClick = useCallback(
        (id: string) => () => {
            if (selectedRestaurant !== id) {
                setSelectedRestaurant(id)
            }
            setRestAccountingModal({ id, setRestAccounting })
        },
        [selectedRestaurant, setRestAccountingModal],
    )

    const onDownloadClick = useCallback(() => {
        if (!restAccounting.length) return
        const KEYS = [
            'restTabNumber',
            'restTitle',
            'totalSum',
            'deliveryPrice',
            'localiPercent',
            'serviceFee',
            'totalPayment',
            'date',
            'was_paid',
        ]

        const FN = (key: string, node: any) => {
            if (key === 'was_paid') {
                return node[key] ? 'Оплачено' : 'Не оплачено'
            }
            if (key === 'date') {
                return moment(node[key]).format('DD.MM.YYYY')
            }
            if (key === 'serviceFee') {
                return node[key]
            }
            if (key === 'totalPayment') {
                return `${node[key]} ₽`
            }
            return ''
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: restAccounting,
            fn: FN,
            fileName: 'restaurant_accounting',
            total: {
                'Дата выплаты': '',
                'Название ресторана': '',
                'Общая сумма': total.totalSumCollected,
                'Процент локали': '',
                'Расчет с рестораном': total.totalPaymentCollected,
                'Статус выплаты': '',
                'Сервисный сбор': total.totalServiceFee,
                'Сумма доставки': total.deliveryPriceCollected,
                'Таб. номер': 'Итого',
            },
        })
    }, [restAccounting])

    const changeStatus = val => {
        if (val === null) {
            return setStatusPaiment(undefined)
        }
        setStatusPaiment(val)
    }

    const changeRestoran = val => {
        if (val === null) {
            return setIdRestaurant(undefined)
        }
        setIdRestaurant(val)
    }

    const optionRes = useMemo(() => {
        if (data) {
            return data.map(item => ({ value: item.rest_id, label: item.restName }))
        }
        return []
    }, [data])

    return (
        <div className='flex flex-col'>
            <HeaderLayout title='Бухгалтерия / Рестораны' className='gap-5 items-center' />
            <div className='p-8'>
                <DateFilterSelect
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    onClickRefresh={onRefreshClick}
                    selectProps={{ options: datePeriodOptions }}
                    rightElement={
                        <>
                            <AppSelect placeholder='Все рестораны' options={optionRes} onChange={changeRestoran} isClearable />
                            <AppSelect
                                isClearable
                                options={[
                                    { label: 'Оплачен', value: true },
                                    { label: 'Неоплачен', value: false },
                                ]}
                                value={statusPaiment}
                                onChange={changeStatus}
                                placeholder='Статус выплат'
                            />

                            <Button
                                variant={EButtonVariant.GREEN}
                                icon='excel'
                                onClick={onDownloadClick}
                                disabled={isLoading || !restAccounting.length}>
                                <MediumText>Download</MediumText>
                            </Button>
                        </>
                    }
                />
                {/*<div className="flex justify-between items-center">*/}
                {/*  <div className="flex gap-1 items-center">*/}
                {/*    <MediumText>С</MediumText>*/}
                {/*    <AppSelect options={[{ label: 'Оплачен', value: true }, { label: 'Неоплачен', value: false }]}*/}
                {/*      value={statusPaiment}*/}
                {/*      onChange={({ value }) => setStatusPaiment(value)} placeholder="Статус выплат"/>*/}
                {/*  </div>*/}
                {/**/}
                {/**/}
                {/*  <Button*/}
                {/*    variant={EButtonVariant.GREEN}*/}
                {/*    icon="excel"*/}
                {/*    onClick={onDownloadClick}*/}
                {/*    disabled={isLoading || !restAccounting.length}*/}
                {/*  >*/}
                {/*    <MediumText>*/}
                {/*      Download*/}
                {/*    </MediumText>*/}
                {/*  </Button>*/}
                {/*</div>*/}
            </div>
            <div className='py-4 px-0'>
                <SuspenseWithSpinner>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <GridTable
                            maxHeight={'95vh'}
                            theads={THEADS.map(head => (
                                <GridTableHead key={head} title={head} />
                            ))}>
                            {restAccounting
                                .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
                                .map(accountingGroup => (
                                    <tr key={accountingGroup.restfinance_id}>
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.restTabNumber || '-'}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.restTitle}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.totalSum}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.deliveryPrice}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.localiPercent}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.serviceFee}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={
                                                accountingGroup.totalPayment === 0
                                                    ? 0
                                                    : accountingGroup.totalPayment > 0
                                                      ? `+${accountingGroup.totalPayment}`
                                                      : accountingGroup.totalPayment
                                            }
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={moment(accountingGroup.date).utc().format('DD.MM.YYYY')}
                                        />
                                        <GridTableItem
                                            onClick={handleClick(accountingGroup)}
                                            onDoubleClick={handleDoubleClick(accountingGroup.restfinance_id)}
                                            isActive={selectedRestaurant === accountingGroup.restfinance_id}
                                            child={accountingGroup.was_paid ? 'Оплачено' : 'Неоплачено'}
                                        />
                                    </tr>
                                ))}

                            <GridTableItem child={'Итого'} />

                            <GridTableItem child={total.totalOrderCount} />

                            <GridTableItem child={total.totalSumCollected} />

                            <GridTableItem child={total.deliveryPriceCollected} />

                            <GridTableItem child={''} />
                            <GridTableItem child={''} />

                            <GridTableItem child={total.totalPaymentCollected} />

                            <GridTableItem child={''} />

                            <GridTableItem child={''} />
                        </GridTable>
                    )}
                </SuspenseWithSpinner>
            </div>
        </div>
    )
}
