import { MediumText, RegularText, AppTable } from 'src/components/'
import { EBorderVariant } from 'src/components/Table'
import { useAtomValue, useSetAtom } from 'jotai'
import classNames from 'classnames'
import { couriersAtom, couriersNoFilterAtom, fetchAllCouriersAtom } from 'src/atoms/allCouriers/allCouriersAtom'
import { Courier } from 'src/services/LocaliRepository'
import { currentCourierAtom, ordersCouriersSearchAtom, setCurrentCourierAtom } from 'src/atoms/allOrders/couriersListAtom'

import convertStringToSearchable from '../../utils/convertStringToSearchable'

import styles from './styles.module.scss'
import { isColorCourer } from '../Couriers/CouriersTable'
import { useCallback, useEffect, useMemo } from 'react'

const HEADS = ['Фамилия', 'Таб. номер', 'Телефон']

const getFilteredData = (list: Courier[] | null, string: string) =>
    string?.length && list
        ? list.filter(item =>
              convertStringToSearchable(item.item.firstName + item.item.surname + item.item.tabNumber + item.item.phoneNumber).includes(
                  convertStringToSearchable(string),
              ),
          )
        : list

export const AllOrdersCouriersList = (): JSX.Element => {
    const currentCourierType = useAtomValue(currentCourierAtom)
    const setCurrentCourier = useSetAtom(setCurrentCourierAtom)
    const ordersCouriersSearch = useAtomValue(ordersCouriersSearchAtom)
    const refreshCouriers = useSetAtom(fetchAllCouriersAtom)

    const data = useAtomValue(couriersNoFilterAtom)
    const filteredData = data.filter(item => !item.item.fired)

    const isColorCourer = useCallback(
        (isActiveOrder: number | undefined, activeCourer: boolean, isActiveToday?: boolean) => {
            if (activeCourer) {
                if (isActiveToday) {
                    if (typeof isActiveOrder === 'number') {
                        if (isActiveOrder > 0) {
                            return '#d10000'
                        }
                    }
                    return '#00c300'
                }
                return 'rgb(147 205 245)'
            }

            if (isActiveToday) {
                if (typeof isActiveOrder === 'number') {
                    if (isActiveOrder > 0) {
                        return '#ffbcbc'
                    }
                }
                return '#bcffbc'
            }
            return ''
        },
        [currentCourierType],
    )

    const renderCellFn = (element: Courier, index: number) => {
        const isActive = currentCourierType?.item.courier_id === element.item.courier_id
        const TextComponent = isActive ? MediumText : RegularText

        if (!currentCourierType?.item.courier_id && index === 0) {
            setCurrentCourier(element)
        }

        if (!element.item.workStatus) {
            return null
        }

        return (
            <tr
                key={element.item.courier_id}
                className={'cursor-pointer'}
                onClick={() => setCurrentCourier(element)}
                style={{ backgroundColor: isColorCourer(element.activeOrderCount, isActive, element.isWorkingToday) }}>
                <td className={styles.orderTd}>
                    <TextComponent>{element.item.surname}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{element.item.tabNumber}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{element.item.phoneNumber}</TextComponent>
                </td>
            </tr>
        )
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refreshCouriers()
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className={styles.listContainer}>
            <AppTable<Courier>
                theads={HEADS}
                tbody={getFilteredData(filteredData, ordersCouriersSearch)}
                renderCellFn={renderCellFn}
                borderVariant={EBorderVariant.TERTIARY}
                table
            />
        </div>
    )
}
